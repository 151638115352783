import { MapsAPILoader } from "@agm/core";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import {
  IRestaurantModel,
  RestaurantType,
} from "src/app/core/models/restaurant.model";
import { FileService } from "src/app/core/services/file.service";
import { ImageCropperComponent } from "../image-cropper/image-cropper.component";
import {
  StateListResponseModel,
  CityListModel,
} from "src/app/core/models/state-list.model";
import { RestaurantRegistrationService } from "src/app/pages/restaurant-registration/restaurant-registration.service";

@Component({
  selector: "app-restaurant-account-information",
  templateUrl: "./restaurant-account-information.component.html",
  styleUrls: ["./restaurant-account-information.component.scss"],
})
export class RestaurantAccountInformationComponent
  implements OnInit, OnChanges
{
  @Input()
  restaurant: IRestaurantModel;

  @Output()
  updateRestaurant: EventEmitter<IRestaurantModel> =
    new EventEmitter<IRestaurantModel>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  stateList: StateListResponseModel[] = [];
  cityList: CityListModel[] = [];

  firstUserState: IRestaurantModel;
  subscriptions: Subscription[] = [];
  avatarPic = "none";

  @Input()
  alert = {
    type: "",
    message: "",
  };
  geoCoder: google.maps.Geocoder;
  @ViewChild("mapSearch")
  public searchElementRef: ElementRef;
  zoom: number;
  formGroup: any;
  restaurantTypes = [RestaurantType.INDIVIDUAL, RestaurantType.RESTAURANT, RestaurantType.Grocery];

  constructor(
    private file: FileService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private restaurantRegistrationService: RestaurantRegistrationService
  ) {}

  ngOnInit(): void {
    this.firstUserState = this.restaurant;
    this.getStateDDList();
    if (this.restaurant) {
      this.loadCityList(this.restaurant.stateId);
    }
  }

  loadCityList(stateId: number) {
    this.restaurantRegistrationService
      .cityList(stateId)
      .subscribe((response: CityListModel[]) => {
        this.cityList = response;
        this.cd.detectChanges();
      });
  }
  getStateDDList() {
    this.restaurantRegistrationService
      .stateList()
      .subscribe((res: StateListResponseModel[]) => {
        this.stateList = res;
        this.cd.detectChanges();
      });
  }

  getCityDDList(stateId: number) {
    this.restaurant.cityId = null;
    this.loadCityList(stateId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.restaurant) {
      this.loadForm();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  loadForm() {
    if (this.restaurant.fssaiCertificate)
      this.restaurant.fssaiCertificateName = this.restaurant.fssaiCertificate
        .split("/")
        .pop();

    if (this.restaurant.registrationCertificate)
      this.restaurant.registrationCertificateName =
        this.restaurant.registrationCertificate.split("/").pop();

    this.formGroup = this.fb.group({
      image: [this.restaurant.image],
      name: [this.restaurant.name, Validators.required],
      address: [this.restaurant.address],
      stateId: [this.restaurant.stateId],
      cityId: [this.restaurant.cityId],
      type: [this.restaurant.type, Validators.required],
      fssaiCertificateName: [this.restaurant.fssaiCertificateName],
      registrationCertificateName: [
        this.restaurant.registrationCertificateName,
      ],
    });

    setTimeout(() => {
      this.initializeLocation();
    }, 1000);
  }

  openFileUpload(id: string) {
    document.getElementById(id).click();
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      return;
    }

    const formValues = this.formGroup.value;
    this.restaurant = Object.assign(this.restaurant, formValues);

    this.updateRestaurant.emit(this.restaurant);
  }

  cancelEdit() {
    this.restaurant = Object.assign({}, this.firstUserState);
    this.loadForm();
    this.cancel.emit();
  }

  getPic() {
    if (!this.restaurant.image) {
      return `url(./assets/media/users/blank.png)`;
    }

    return `url('${this.restaurant.image}')`;
  }

  deletePic() {
    this.restaurant.image = "";
  }

  uploadFile(event) {
    const modalRef = this.modalService.open(ImageCropperComponent);
    modalRef.componentInstance.image = event;
    modalRef.result.then((resp) => {
      this.uploadImage(resp);
    });
  }

  uploadImage(file: File) {
    //var file: File = event.target.files[0];
    let fileName = file.name;
    let fileExtension = fileName.split(".").pop();
    var mimeType = file.type;
    if (
      mimeType.match(/image\/*/) == null ||
      !this.file.isValidImage(fileExtension)
    ) {
      this.alert = {
        type: "error",
        message: "Only png, jpg, jpeg files are allowed.",
      };
      return;
    }
    this.file.upload(file, "restaurant").subscribe(
      (resp) => {
        this.alert = {
          type: "",
          message: "",
        };
        this.formGroup.controls["image"].setValue(resp);
        this.restaurant.image = resp;
        this.cd.detectChanges();
      },
      (error) => {
        this.alert = {
          type: "error",
          message: "Error Uploading the image.",
        };
      }
    );
  }

  controlHasError(validation: string, controlName: string) {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }
  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  ///--------------------------Google Maps Location Code -------------------------------------------//

  initializeLocation() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["geocode"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          debugger;
          //set latitude, longitude and zoom
          this.restaurant.latitude = place.geometry.location.lat();
          this.restaurant.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.restaurant.latitude, this.restaurant.longitude);
          this.cd.detectChanges();
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.restaurant.latitude = position.coords.latitude;
        this.restaurant.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.restaurant.latitude, this.restaurant.longitude);
        this.cd.detectChanges();
      });
    }
  }

  markerDragEnd($event: any) {
    this.restaurant.latitude = $event.coords.lat;
    this.restaurant.longitude = $event.coords.lng;
    this.getAddress(this.restaurant.latitude, this.restaurant.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            // this.restaurant.address = results[0].formatted_address;
            // this.formGroup.controls['address'].setValue(results[0].formatted_address);
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  handleFileClick(fileKey, inputId) {
    const filePath = this.restaurant[fileKey];
    if (filePath) window.open(filePath);
    else this.openFileUpload(inputId);
  }

  uploadFssaiCertificate(event) {
    var file: File = event.target.files[0];
    let fileName = file.name;
    let fileExtension = fileName.split(".").pop();
    this.formGroup.controls["fssaiCertificateName"].setValue(fileName);
    if (
      !this.file.isValidDoc(fileExtension) &&
      !this.file.isValidImage(fileExtension)
    ) {
      this.alert = {
        type: "error",
        message: "Only Document and Image files are allowed.",
      };
      return;
    }
    // if (mimeType.match(/image\/*/) == null || !this.file.isValidImage(fileExtension)) {
    //   this.alert = {
    //     type: 'error',
    //     message: "Only png, jpg, jpeg, pdf files are allowed."
    //   };
    //   return;
    // }

    this.file.uploadDocAndImage(file, "restaurant-registration").subscribe(
      (resp) => {
        this.alert = {
          type: "",
          message: "",
        };
        this.restaurant.fssaiCertificate = resp;
        this.cd.detectChanges();
      },
      (error) => {
        this.alert = {
          type: "error",
          message: "Error Uploading the image.",
        };
      }
    );
  }

  uploadRegistrationCertificate(event) {
    var file: File = event.target.files[0];
    let fileName = file.name;
    let fileExtension = fileName.split(".").pop();
    this.formGroup.controls["registrationCertificateName"].setValue(fileName);
    if (
      !this.file.isValidDoc(fileExtension) &&
      !this.file.isValidImage(fileExtension)
    ) {
      this.alert = {
        type: "error",
        message: "Only Document and Image files are allowed.",
      };
      return;
    }

    this.file.uploadDocAndImage(file, "restaurant-registration").subscribe(
      (resp) => {
        this.alert = {
          type: "",
          message: "",
        };
        this.restaurant.registrationCertificate = resp;
        this.cd.detectChanges();
      },
      (error) => {
        this.alert = {
          type: "error",
          message: "Error Uploading the image.",
        };
      }
    );
  }
}
