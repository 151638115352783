export enum RestaurantStatus {
  PENDING = "Awaiting Approval",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  TEMPORARY_CLOSED = "Temporary Closed",
  DELETED = "Deleted",
  SUSPEND = "Suspended",
}

export enum RestaurantType {
  INDIVIDUAL = "Individual Kitchen",
  RESTAURANT = "Restaurant",
  Grocery = "Grocery",
}

export enum RestaurantOperationStatus {
  ONLINE = "Online",
  OFFLINE = "Offline",
}

export interface IRestaurantScheduleAPIModel {
  id: number;
  startTime: string;
  endTime: string;
  days: string[];
}
export class RestaurantScheduleModel {
  constructor(
    public id: number,
    public startTime: TimeModel,
    public endTime: TimeModel,
    public days: string[]
  ) {}

  static adapt(data: any) {
    return new RestaurantScheduleModel(
      data.id,
      TimeModel.adapt(data.startTime),
      TimeModel.adapt(data.endTime),
      data.days
    );
  }
}

export class TimeModel {
  constructor(public hour: number, public minute: number) {}

  static adapt(timeString: string) {
    if (timeString) {
      let time = timeString.split(":");
      return new TimeModel(parseInt(time[0]), parseInt(time[1]));
    }
  }

  static parse(timeModel: TimeModel) {
    let hourString =
      timeModel.hour < 10 ? "0" + timeModel.hour : timeModel.hour.toString();
    let minuteString =
      timeModel.minute < 10
        ? "0" + timeModel.minute
        : timeModel.minute.toString();
    return `${hourString}:${minuteString}`;
  }
}

export interface IRestaurantRegistrationModel extends IRestaurantModel {
  contactFirstName: string;
  contactLastName: string;
  email: string;
  mobile: string;
  password: string;
  confirmPassword: string;

  //Banking Details
  accountNumber: string;
  confirmAccountNumber: string;
  accountName: string;
  bankCode: string;
  bankName: string;
  referralCode: string;
  fssaiCertificate: string;
  registrationCertificate: string;

  fssaiCertificateName?: string;
  registrationCertificateName?: string;

  alternateMobile?: string;
  shareLink?: string;
}

export interface IRestaurantModel {
  id: number;
  name: string;
  stateId: number;
  cityId: number;
  address: string;
  latitude: number;
  longitude: number;
  image: string;
  status: RestaurantStatus | string;
  restaurantSchedule: IRestaurantScheduleAPIModel[];
  type: RestaurantType | string;
  fssaiCertificate: string;
  registrationCertificate: string;

  fssaiCertificateName?: string;
  registrationCertificateName?: string;
  shareCode?: string;
  shareLink?: string;
  city?: string;
  state?: string;
}

export interface IRestaurantPaymentInfoModel {
  restaurantId: number;
  accountNumber: string;
  accountName: string;
  bankName: string;
  bankCode: string;
}

export interface GetRestaurantCommissionModel {
  commission: number;
}

export interface UpdateRestaurantCommisonModel {
  commission: number;
}
