<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        <ng-container *ngIf="!id;else vendorText">Become a Vendor </ng-container>
        <ng-template #vendorText> Edit Restaurant Details </ng-template>
      </h3>

    </div>
    <div class="card-toolbar">
      <a class="btn btn-light" href="/auth/login" routerLink="/auth/login">
        <i class="fa fa-arrow-left"></i>Back
      </a>
    </div>
  </div>
  <div class="card-body">
    <!--begin: Wizard -->
    <div #wizard class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first">
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Form Wizard Nav -->
        <div class="wizard-steps">
          <a class="wizard-step" href="javascript:;" data-wizard-type="step" data-wizard-state="current">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-3x" [inlineSVG]="'./assets/media/svg/icons/General/User.svg'"></span>
              </div>
              <div class="wizard-label">
                <div class="wizard-title">Restaurant Details</div>
                <div class="wizard-desc">Setup Your Restaurant Details</div>
              </div>
            </div>
          </a>
          <a class="wizard-step" href="javascript:;" data-wizard-type="step">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-3x" [inlineSVG]="'./assets/media/svg/icons/Map/Compass.svg'"></span>
              </div>
              <div class="wizard-label">
                <div class="wizard-title">Setup Locations</div>
                <div class="wizard-desc">Choose Your Location Map</div>
              </div>
            </div>
          </a>
          <a class="wizard-step" href="javascript:;" data-wizard-type="step">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-3x" [inlineSVG]="
                      './assets/media/svg/icons/Shopping/Credit-card.svg'
                    "></span>
              </div>
              <div class="wizard-label">
                <div class="wizard-title">Banking Details</div>
                <div class="wizard-desc">Add Your Account Details</div>
              </div>
            </div>
          </a>
          <a class="wizard-step" href="javascript:;" data-wizard-type="step">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-3x" [inlineSVG]="
                      './assets/media/svg/icons/General/Thunder-move.svg'
                    "></span>
              </div>
              <div class="wizard-label">
                <div class="wizard-title">Outlet Timings</div>
                <div class="wizard-desc">Add Your Schedule</div>
              </div>
            </div>
          </a>
          <a class="wizard-step" href="javascript:;" data-wizard-type="step">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-3x" [inlineSVG]="'./assets/media/svg/icons/General/Like.svg'"></span>
              </div>
              <div class="wizard-label">
                <div class="wizard-title">Completed!</div>
                <div class="wizard-desc">Review and Submit</div>
              </div>
            </div>
          </a>
        </div>
        <!--end: Form Wizard Nav -->
      </div>
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <!--begin: Form Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Form Wizard Step 1-->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Enter your Restaurant Details
                </h4>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v2__form">
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Restaurant Name</label>
                          <input type="text" class="form-control" name="name" #name="ngModel" [(ngModel)]="model.name"
                            placeholder="Restaurant Name" value="" required />
                          <span class="form-text text-danger" *ngIf="showError && name.errors?.required">Please enter
                            your
                            Restaurant name.</span>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Restaurant Type</label>
                          <select class="form-control" name="type" #type="ngModel" [(ngModel)]="model.type"
                            placeholder="Restaurant Type" required>
                            <option *ngFor="let type of restaurantTypes">{{type}}</option>
                          </select>
                          <span class="form-text text-danger" *ngIf="showError && type.errors?.required">
                            Please select
                            Restaurant Type.</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Catering Certificate (optional)</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="fssaiCertificate" readonly
                              [(ngModel)]="model.fssaiCertificateName" #fssaiCertificate="ngModel" placeholder=""
                              (click)="openFileUpload('fssai_upload')" />
                            <div class="input-group-append">
                              <span class="input-group-text" (click)="openFileUpload('fssai_upload')">
                                <i class="fa fa-paperclip"></i>
                              </span>
                            </div>
                            <input type="file" id="fssai_upload" class="d-none" name="fssai_upload"
                              (change)="uploadFssaiCertificate($event)" accept=".pdf, .docx, .jpeg, .png" />
                          </div>
                          <span class="form-text text-danger"
                            *ngIf="showError && fssaiCertificate.errors?.required">Please upload
                            FSSAI certificate.</span>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Company Registration Certificate (optional)</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="registrationCertificate" readonly
                              [(ngModel)]="model.registrationCertificateName" #registrationCertificate="ngModel"
                              placeholder="" (click)="openFileUpload('certificate_upload')" />
                            <div class="input-group-append">
                              <span class="input-group-text" (click)="openFileUpload('certificate_upload')">
                                <i class="fa fa-paperclip"></i>
                              </span>
                            </div>
                            <input type="file" id="certificate_upload" class="d-none" name="certificate_upload"
                              (change)="uploadRegistrationCertificate($event)" accept=".pdf, .docx, .jpeg, .png" />
                          </div>
                          <span class="form-text text-danger"
                            *ngIf="showError && registrationCertificate.errors?.required">Please upload
                            Registration certificate.</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col form-group pb-0">
                        <label>Contact Information</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>First Name</label>
                          <input type="text" class="form-control" name="contactFirstName"
                            [(ngModel)]="model.contactFirstName" placeholder="First Name" value="" required
                            #contactFirstName="ngModel" />
                          <span class="form-text text-danger"
                            *ngIf="showError && contactFirstName.errors?.required">Please enter first name.</span>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input type="text" class="form-control" name="contactLastName"
                            [(ngModel)]="model.contactLastName" placeholder="Last Name" value=""
                            #contactLastName="ngModel" required />
                          <span class="form-text text-danger"
                            *ngIf="showError && contactLastName.errors?.required">Please enter your last name.</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Phone</label>
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend"><span class="input-group-text"> +234</span></div>
                            <input type="tel" class="form-control" name="phone" [(ngModel)]="model.mobile"
                              pattern="^\d{10}|d{11} " placeholder="phone" value="" #mobile="ngModel" required />
                          </div>
                          <span class="form-text text-danger" *ngIf="showError && mobile.errors">Please enter valid
                            phone
                            number.</span>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Email</label>
                          <input type="email" class="form-control" name="email" [(ngModel)]="model.email"
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+" placeholder="Email" value=""
                            #email="ngModel" required />
                          <span class="form-text text-danger" *ngIf="showError && email.errors">Please enter valid
                            email
                            address.</span>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="!id">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Password</label>
                          <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
                            placeholder="phone" value="" #password="ngModel" required />
                          <span class="form-text text-danger" *ngIf="showError && password.errors">Please enter the
                            Password.</span>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Confirm Password</label>
                          <input type="password" class="form-control" name="confirmPassword"
                            [(ngModel)]="model.confirmPassword" placeholder="Confirm Password" value=""
                            #confirmPassword="ngModel" required />
                          <span class="form-text text-danger" *ngIf="showError && confirmPassword.errors">Please enter
                            the Confirm Password.</span>
                          <span class="form-text text-danger"
                            *ngIf="showError && model.password != model.confirmPassword && !confirmPassword.errors">Confirm
                            Password is not matching
                            with
                            Password</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Alternate Phone</label>
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend"><span class="input-group-text"> +234</span></div>
                            <input type="tel" class="form-control" name="phone" [(ngModel)]="model.alternateMobile"
                              pattern="^\d{10}|d{11} " placeholder="phone" value="" #alternateMobile="ngModel" />
                          </div>
                          <span class="form-text text-danger" *ngIf="showError && alternateMobile.errors">Please enter valid
                            phone
                            number.</span>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Referral Code (optional)</label>
                          <input type="tel" class="form-control" name="referralCode" [(ngModel)]="model.referralCode"
                            placeholder="referralCode" value="" #referralCode="ngModel" />
                          <span class="form-text text-danger" *ngIf="showError && referralCode.errors">Please enter
                            valid Code.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 1-->

              <!--begin: Form Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Setup Your Current Location
                </h4>
                <div class="form-group">
                  <label>Search Location</label>
                  <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                    placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off"
                    type="text" #mapSearch>
                </div>
                <div>
                  <agm-map class="map-container" [latitude]="model.latitude" [longitude]="model.longitude">
                    <agm-marker [latitude]="model.latitude" *ngIf="model.latitude" [longitude]="model.longitude"
                      [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                  </agm-map>
                </div>
                <div class="mt-2">
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text" class="form-control" name="address" [(ngModel)]="model.address"
                      placeholder="Address" value="" #address="ngModel" />
                  </div>
                </div>
                <span class="form-text text-danger" *ngIf="showError">
                  Please select the Restaurant Location.
                </span>
                <div class="row"> 
<div class="col-lg-6">
  <div class="">
    <div class="form-group">
      <label>State</label>
      <div *ngIf="stateList">
        <ng-select class="form-control p-0" [(ngModel)]="model.stateId" #stateId="ngModel" name="stateId" placeholder="Select State" (change)="getCityDDList($event)">

          <ng-option *ngFor="let su of stateList" 
            [value]="su.id">{{su.name}}</ng-option>
        </ng-select>
        <div class="form-text text-danger" *ngIf="showError">
          State is required
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-6">
  <div class="">
    <div class="form-group">
      <label>City </label>
      <div *ngIf="cityList">
        <ng-select class="form-control p-0" #cityId="ngModel" [(ngModel)]="model.cityId" placeholder="Select City" name="cityId">

          <ng-option *ngFor="let su of cityList" [value]="su.id">{{su.name}}</ng-option>

        </ng-select>

        <div class="form-text text-danger" *ngIf="showError">
          City is required
        </div>
      </div>
    </div>
  </div>
</div>
                </div>
               
                
              </div>
              <!--end: Form Wizard Step 2-->
              <!--begin: Form Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Setup Your Banking Details
                </h4>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Account Number</label>
                      <input type="password" class="form-control" name="accountNumber" [(ngModel)]="model.accountNumber"
                        pattern="^\d{10}" placeholder="Account Number" value="" #accountNumber="ngModel" required />
                      <span class="form-text text-danger" *ngIf="showError && accountNumber.errors.required">Please
                        enter the
                        Account Number.</span>
                      <span class="form-text text-danger" *ngIf="showError && accountNumber.errors.pattern">Account
                        Number should have 10 digits.
                      </span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Confirm Account Number</label>
                      <input type="text" class="form-control" name="confirmAccountNumber"
                        [(ngModel)]="model.confirmAccountNumber" placeholder="Confirm Account Number" value=""
                        pattern="^\d{10}" #confirmAccountNumber="ngModel" required />
                      <span class="form-text text-danger" *ngIf="showError && confirmAccountNumber.errors">Please enter
                        the Confirm Password.</span>
                      <span class="form-text text-danger"
                        *ngIf="showError && model.accountNumber != model.confirmAccountNumber && !confirmAccountNumber.errors">Account
                        Number
                        is not matching</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Account Name</label>
                      <input type="text" class="form-control" name="accountName" [(ngModel)]="model.accountName"
                        placeholder="Account Name" value="" required #accountName="ngModel" />
                      <span class="form-text text-danger" *ngIf="showError && accountName.errors?.required">Please
                        enter Account name.</span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Bank</label>
                      <select class="form-control" name="bankCode" #bankCode="ngModel" [(ngModel)]="model.bankCode"
                        (change)="getBankName()" placeholder="Bank Name" required>
                        <option *ngFor="let bank of bankList" [value]="bank.code">{{bank.name}}</option>
                      </select>
                      <span class="form-text text-danger" *ngIf="showError && bankCode.errors?.required">
                        Please select
                        Bank.</span>
                    </div>
                  </div>
                </div>

              </div>
              <!--end: Form Wizard Step 3-->

              <!--begin: Form Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Select your Services
                  <button class="btn btn-info float-right" type="button" (click)="addSchedule()">Add Schedule</button>
                </h4>
                <!-- begin:Table -->
                <div class="table-responsive angular-bootstrap-table">
                  <table class="table table-head-custom table-vertical-center overflow-hidden">
                    <thead>
                      <th>
                        Start Time
                      </th>
                      <th>
                        End Time
                      </th>
                      <th>
                        Days
                      </th>
                      <th>Actions</th>
                    </thead>
                    <tbody *ngIf="model.restaurantSchedule && model.restaurantSchedule.length">
                      <tr *ngFor="let schedule of model.restaurantSchedule">
                        <td>{{ schedule.startTime | timeFormatter }}</td>
                        <td>{{ schedule.endTime | timeFormatter }}</td>
                        <td>{{ getDaysText(schedule.days) }}</td>
                        <td>

                          <a title="Edit Schedule" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            (click)="editSchedule(schedule)">
                            <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" cacheSVG="true"
                              class="svg-icon svg-icon-md svg-icon-primary">
                            </span>
                          </a>

                          <a title="Delete Schedule" class="btn btn-icon btn-light btn-hover-danger btn-sm"
                            (click)="deleteSchedule(schedule)">
                            <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" cacheSVG="true"
                              class="svg-icon svg-icon-md svg-icon-danger">
                            </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!model.restaurantSchedule || !model.restaurantSchedule.length">
                      <tr>
                        <td colspan="3">
                          <span *ngIf="!showError">
                            No Schedules Available.
                          </span>
                          <span class="text-danger" *ngIf="showError">
                            Please add the schedule to continue.
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end: Table -->

              </div>
              <!--end: Form Wizard Step 4-->



              <!--begin: Form Wizard Step 5-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Review your Details and Submit
                </h4>
                <div class="border-bottom mb-5 pb-5">
                  <!--begin::Alert-->
                  <div class="alert alert-custom alert-light-success fade show mb-10" *ngIf="showSuccessAlert"
                    role="alert">
                    <div class="alert-icon">
                      <span class="svg-icon svg-icon-3x svg-icon-success"
                        [inlineSVG]="'./assets/media/svg/icons/Code/Done-circle.svg'" cacheSVG="true">
                      </span>
                    </div>
                    <div class="alert-text font-weight-bold" *ngIf="!id">
                      Registration request submitted successfully. Please allow us some time to verify the details.
                      We will get back to you soon.
                    </div>
                    <div class="alert-text font-weight-bold" *ngIf="id">
                      Registration details updated successfully.
                    </div>
                    <div class="alert-close">
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">
                          <i class="ki ki-close"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <!--end::Alert-->
                  <!--begin::Alert-->
                  <div class="alert alert-custom alert-light-danger fade show mb-10" *ngIf="showErrorAlert"
                    role="alert">
                    <div class="alert-icon">
                      <span class="svg-icon svg-icon-3x svg-icon-danger"
                        [inlineSVG]="'./assets/media/svg/icons/Code/Info-circle.svg'" cacheSVG="true">
                      </span>
                    </div>
                    <div class="alert-text font-weight-bold" *ngIf="errorMessage">
                      {{errorMessage}}
                    </div>
                    <div class="alert-close">
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">
                          <i class="ki ki-close"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <!--end::Alert-->
                  <div class="font-weight-bolder mb-3">Restaurant Details:</div>
                  <div class="line-height-xl">
                    {{model.name}}
                    <br />
                    Type: {{model.type}} <br />
                    Contact Person: {{model.contactFirstName}} {{model.contactLastName}}
                    <br />
                    Phone: {{model.mobile}} <br />
                    Email: {{model.email}} <br />
                    Catering Certificate: <a href="{{model.fssaiCertificate}}"
                      target="_blank">{{model.fssaiCertificateName}} </a> <br />
                    Company Registration Certificate: <a href="{{model.registrationCertificate}}"
                      target="_blank">{{model.registrationCertificateName}}</a> <br />
                    Referral Code: {{model.referralCode}}
                  </div>
                </div>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bolder mb-3">
                    Account Details:
                  </div>
                  <div class="line-height-xl">
                    Account Number: {{model.accountNumber}}
                    <br />
                    Account Name: {{model.accountName}}
                    <br />
                    Bank Name: {{model.bankName}}
                  </div>
                </div>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bolder mb-3">
                    Location Address:
                  </div>
                  <div class="line-height-xl">
                    Latitude: {{model.latitude}}
                    <br />
                    Longitude: {{model.longitude}}
                    <br />
                    Address: {{model.address}}
                  </div>
                </div>
                <div class="mb-5 pb-5">
                  <div class="font-weight-bolder mb-3">Restaurant Schedule:</div>
                  <div class="line-height-xl">
                    <span *ngFor="let schedule of model.restaurantSchedule">
                      {{ getDaysText(schedule.days)}} : {{schedule.startTime | timeFormatter}} - {{schedule.endTime |
                      timeFormatter}}
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 5-->

              <!--begin: Form Actions -->
              <div class="d-flex justify-content-between border-top pt-10" *ngIf="!showSuccessAlert && !isSubmitted">
                <div class="mr-2">
                  <div class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev">
                    Previous
                  </div>
                </div>
                <div>
                  <div (click)="onSubmit()" class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit">
                    Submit
                  </div>
                  <div class="btn btn-primary font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                    Next Step
                  </div>
                </div>
              </div>
              <!--end: Form Actions -->
            </form>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </div>
    </div>
    <!--end: Wizard -->
  </div>
</div>