import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import KTWizard from "../../../assets/js/components/wizard";
import { KTUtil } from "../../../assets/js/components/util";
import { MapsAPILoader } from "@agm/core";
import { ValidationHelper } from "src/app/core/helpers/validation.helper";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ManageSchedulePopupComponent } from "./manage-schedule-popup/manage-schedule-popup.component";
import { RestaurantRegistrationService } from "./restaurant-registration.service";
import {
  IRestaurantRegistrationModel,
  RestaurantScheduleModel,
  RestaurantType,
  TimeModel,
} from "src/app/core/models/restaurant.model";
import { WeekDaysArray } from "src/app/core/app.constants";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";
import { FileService } from "src/app/core/services/file.service";
import { CityListModel, StateListResponseModel } from "src/app/core/models/state-list.model";

@Component({
  selector: "app-restaurant-registration",
  templateUrl: "./restaurant-registration.component.html",
  styleUrls: ["./restaurant-registration.component.scss"],
})
export class RestaurantRegistrationComponent implements OnInit {
  @ViewChild("wizard", { static: true }) el: ElementRef;
  showError: boolean;
  isSubmitted: boolean;
  alert: any;
  mapInitialized: boolean;
  showSuccessAlert: boolean;
  showErrorAlert: boolean;
  errorMessage: string;
  weekDays = WeekDaysArray;
  restaurantTypes = [RestaurantType.INDIVIDUAL, RestaurantType.RESTAURANT, RestaurantType.Grocery];
  private zoom: any;
  model: IRestaurantRegistrationModel = {
    stateId:null,
    cityId:null,
    contactFirstName: "",
    contactLastName: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    address: "",
    latitude: 1,
    longitude: 1,
    image: "",
    status: "",
    type: "",
    restaurantSchedule: [],
    id: 0,
    accountName: "",
    accountNumber: "",
    confirmAccountNumber: "",
    bankCode: "",
    bankName: "",
    referralCode: "",
    fssaiCertificate: "",
    registrationCertificate: "",

    fssaiCertificateName: "",
    registrationCertificateName: "",
  };
  submitted = false;
  wizard: any;
  stateList: StateListResponseModel;
  cityList: CityListModel[] = [];

  private geoCoder;
  public id: any;
  public bankList;

  @ViewChild("mapSearch")
  public searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private restaurantRegistrationService: RestaurantRegistrationService,
    private activatedRoute: ActivatedRoute,
    private file: FileService
  ) {}

  ngOnInit() {
    this.getBanks();
    this.getRestaurantDetails();
    this.getStateDDList();
   
  }
  getStateDDList() {
    this.restaurantRegistrationService
      .stateList()
      .subscribe((res: StateListResponseModel) => {
        this.stateList = res;
      });
  }

  getCityDDList(stateId:number) {
    this.model.cityId=null;
    this.restaurantRegistrationService.cityList(stateId).subscribe((response:CityListModel[])=>{
      this.cityList = response;
      this.cd.detectChanges();
    })
  }

  getBanks() {
    this.restaurantRegistrationService
      .bankList()
      .pipe(take(1))
      .subscribe((resp) => {
        this.bankList = resp;
      });
  }

  getRestaurantDetails() {
    this.activatedRoute.paramMap.subscribe((resp) => {
      if (resp.get("id")) {
        this.id = resp.get("id");
        this.restaurantRegistrationService
          .getRestaurantById(this.id)
          .pipe(take(1))
          .subscribe((resp: any) => {
            this.model = resp;
            if (this.model.fssaiCertificate)
              this.model.fssaiCertificateName = this.model?.fssaiCertificate
                .split("/")
                .pop();

            if (this.model.registrationCertificate)
              this.model.registrationCertificateName =
                this.model?.registrationCertificate.split("/").pop();
            this.model.confirmAccountNumber = this.model.accountNumber;
          });
      }
    });
  }

  openFileUpload(id: string) {
    document.getElementById(id).click();
  }

  ngAfterViewInit(): void {
    // Initialize form wizard
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1,
    });

    // Validation before going to next page
    this.wizard.on("beforeNext", (wizardObj) => {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    this.wizard.on("change", (event) => {
      if (event.getNewStep() > event.getStep()) {
        if (!this.validateStep(event.getStep())) {
          event.stop();
          this.showError = true;
          this.cd.detectChanges();
          return;
        } else {
          this.showError = false;
          this.cd.detectChanges();
        }
      } else {
        this.showError = false;
        this.cd.detectChanges();
      }
      setTimeout(() => {
        KTUtil.scrollTop();
        if (event.currentStep == 2 && !this.mapInitialized) {
          this.initializeLocation();
          this.mapInitialized = true;
        }
      }, 500);
    });
  }

  validateStep(stepId: number): boolean {
    switch (stepId) {
      case 1:
        return (
          this.model.name &&
          this.model.contactFirstName &&
          this.model.contactLastName &&
          this.model.type &&
          ValidationHelper.isValidMobileNo(this.model.mobile) &&
          ValidationHelper.isValidEmail(this.model.email) &&
          (this.id || this.model.password) &&
          (this.id || this.model.password == this.model.confirmPassword)
        );
        return true;
      case 2:
        return this.model.latitude &&
          this.model.latitude != 1 &&
          this.model.longitude &&
          this.model.longitude != 1 &&
          this.model.address
          ? true
          : false;
      case 3:
        return this.model.accountNumber &&
          this.model.confirmAccountNumber &&
          ValidationHelper.isValidAccountNo(this.model.accountNumber) &&
          this.model.bankCode &&
          this.model.accountName &&
          this.model.accountNumber == this.model.confirmAccountNumber
          ? true
          : false;
      case 4:
        return this.model.restaurantSchedule &&
          this.model.restaurantSchedule.length
          ? true
          : false;
      case 5:
        return true;
    }
    return false;
  }

  addSchedule() {
    this.openSchedulePopup({}).then((schedule) => {
      this.model.restaurantSchedule.push(schedule);
      this.cd.detectChanges();
    });
  }

  editSchedule(schedule) {
    this.openSchedulePopup(schedule).then((schedule) => {
      this.cd.detectChanges();
    });
  }

  deleteSchedule(schedule) {
    let index = this.model.restaurantSchedule.findIndex((s) => s == schedule);
    if (index > -1) {
      this.model.restaurantSchedule.splice(index, 1);
    }
  }

  getBankName() {
    this.model.bankName = this.bankList.find(
      (f) => f.code == this.model.bankCode
    ).name;
  }

  openSchedulePopup(scheduleInput) {
    const modalRef = this.modalService.open(ManageSchedulePopupComponent);
    modalRef.componentInstance.schedule =
      RestaurantScheduleModel.adapt(scheduleInput);
    return modalRef.result.then((schedule: RestaurantScheduleModel) => {
      if (schedule) {
        scheduleInput.startTime = TimeModel.parse(schedule.startTime);
        scheduleInput.endTime = TimeModel.parse(schedule.endTime);
        scheduleInput.days = schedule.days;
      }
      return scheduleInput;
    });
  }

  onSubmit() {
    if (this.id) this.save();
    else if (!this.isSubmitted) {
      this.isSubmitted = true;
      this.restaurantRegistrationService
        .registerRestaurant(this.model)
        .subscribe(
          (resp) => {
            this.showSuccessAlert = true;
            this.showErrorAlert = false;
            this.cd.detectChanges();
          },
          (error) => {
            this.showErrorAlert = true;
            this.isSubmitted = false;
            this.errorMessage = error;
            this.cd.detectChanges();
          }
        );
    }
  }

  save() {
    this.restaurantRegistrationService
      .saveRestaurant(this.id, this.model)
      .subscribe(
        (resp) => {
          this.showSuccessAlert = true;
          this.showErrorAlert = false;
          this.cd.detectChanges();
          history.back();
        },
        (error) => {
          this.showErrorAlert = true;
          this.errorMessage = error;
          this.cd.detectChanges();
        }
      );
  }

  ngOnDestroy() {
    this.wizard = undefined;
  }

  getDaysText(days: any[]) {
    let dayString = days.map((day) => {
      return this.weekDays.find((d) => d.value == day).name;
    });

    return dayString.join(",");
  }

  ///--------------------------Google Maps Location Code -------------------------------------------//

  initializeLocation() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["geocode"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          debugger;
          //set latitude, longitude and zoom
          this.model.latitude = place.geometry.location.lat();
          this.model.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.model.latitude, this.model.longitude);
          this.cd.detectChanges();
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.model.latitude = position.coords.latitude;
        this.model.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.model.latitude, this.model.longitude);
        this.cd.detectChanges();
      });
    }
  }

  markerDragEnd($event: any) {
    this.model.latitude = $event.coords.lat;
    this.model.longitude = $event.coords.lng;
    this.getAddress(this.model.latitude, this.model.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.model.address = results[0].formatted_address;
            // if (results[0].address_components[results[0].address_components.length - 1].types[0] == "postal_code") {
            //   this.model.PinCode = parseInt(results[0].address_components[results[0].address_components.length - 1].long_name);
            // }
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  uploadFssaiCertificate(event) {
    var file: File = event.target.files[0];
    let fileName = file.name;
    let fileExtension = fileName.split(".").pop();
    var mimeType = file.type;
    this.model.fssaiCertificateName = fileName;
    if (
      !this.file.isValidDoc(fileExtension) &&
      !this.file.isValidImage(fileExtension)
    ) {
      this.alert = {
        type: "error",
        message: "Only Document and Image files are allowed.",
      };
      return;
    }
    // if (mimeType.match(/image\/*/) == null || !this.file.isValidImage(fileExtension)) {
    //   this.alert = {
    //     type: 'error',
    //     message: "Only png, jpg, jpeg, pdf files are allowed."
    //   };
    //   return;
    // }

    this.file.uploadDocAndImage(file, "restaurant-registration").subscribe(
      (resp) => {
        this.alert = {
          type: "",
          message: "",
        };
        this.model.fssaiCertificate = resp;
        this.cd.detectChanges();
      },
      (error) => {
        this.alert = {
          type: "error",
          message: "Error Uploading the image.",
        };
      }
    );
  }

  uploadRegistrationCertificate(event) {
    var file: File = event.target.files[0];
    let fileName = file.name;
    let fileExtension = fileName.split(".").pop();
    var mimeType = file.type;
    this.model.registrationCertificateName = fileName;
    if (
      !this.file.isValidDoc(fileExtension) &&
      !this.file.isValidImage(fileExtension)
    ) {
      this.alert = {
        type: "error",
        message: "Only Document and Image files are allowed.",
      };
      return;
    }

    this.file.uploadDocAndImage(file, "restaurant-registration").subscribe(
      (resp) => {
        this.alert = {
          type: "",
          message: "",
        };
        this.model.registrationCertificate = resp;
        this.cd.detectChanges();
      },
      (error) => {
        this.alert = {
          type: "error",
          message: "Error Uploading the image.",
        };
      }
    );
  }
}
