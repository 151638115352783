<ng-container *ngIf="restaurant">
  <div class="card card-custom card-stretch" [formGroup]="formGroup">
    <!-- begin::Loading -->

    <!-- end::Loading -->
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Restaurant Information</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Update your restaurant informaiton</span>
      </div>
      <div class="card-toolbar">
        <button type="submit" [disabled]="formGroup.invalid" class="btn btn-success mr-2" (click)="save()">Save
          Changes</button>
        <button type="reset" class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
      </div>
    </div>
    <!--end::Header-->
    <app-alert *ngIf="alert && alert.type" [type]="alert.type" [message]="alert.message"></app-alert>

    <!--begin::Form-->
    <div class="form">
      <!--begin::Body-->
      <div class="card-body">
        <!-- <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-6">
              <h5 class="font-weight-bold mb-6">Customer Info</h5>
            </div>
          </div> -->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Image</label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar"
              [class.image-input-empty]="!restaurant.image" [style.backgroundImage]="''">
              <div class="image-input-wrapper" [style.backgroundImage]="getPic()"></div>
              <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input type="file" name="profile_avatar" (change)="uploadFile($event)" accept=".png, .jpg, .jpeg" />
                <input type="hidden" name="profile_avatar_remove" formControlName="image" />
              </label>
              <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <!-- <span *ngIf="restaurant.image"
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  (click)="deletePic()"
                  data-action="remove" data-toggle="tooltip" title="" data-original-title="Remove avatar">
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span> -->
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Restaurant Name</label>
          <div class="col-lg-9 col-xl-6">
            <input class="form-control form-control-lg form-control-solid" type="text" formControlName="name"
              [class.is-invalid]="isControlInvalid('name')" [class.is-valid]="isControlValid('name')" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Restaurant Type</label>
          <div class="col-lg-9 col-xl-6">
            <select class="form-control form-control-lg form-control-solid" formControlName="type"
              [class.is-invalid]="isControlInvalid('type')" [class.is-valid]="isControlValid('type')">
              <option *ngFor="let type of restaurantTypes" value="{{type}}">{{type}}</option>
            </select>

          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Catering Certificate</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group">
              <input type="text" class="form-control form-control-lg form-control-solid" type="text" readonly
                (click)="handleFileClick('fssaiCertificate','fssai_upload')" formControlName="fssaiCertificateName"
                [class.is-invalid]="isControlInvalid('fssaiCertificateName')"
                [class.is-valid]="isControlValid('fssaiCertificateName')" />
              <div class="input-group-append">
                <span class="input-group-text" (click)="openFileUpload('fssai_upload')">
                  <i class="fa fa-paperclip"></i>
                </span>
              </div>
              <input type="file" id="fssai_upload" class="d-none" name="fssai_upload"
                (change)="uploadFssaiCertificate($event)" accept=".pdf, .docx, .jpeg, .png" />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Company Registration Certificate </label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group">
              <input type="text" class="form-control form-control-lg form-control-solid" type="text"
                (click)="handleFileClick('registrationCertificate','certificate_upload')"
                formControlName="registrationCertificateName" readonly
                [class.is-invalid]="isControlInvalid('registrationCertificateName')"
                [class.is-valid]="isControlValid('registrationCertificateName')" />
              <div class="input-group-append">
                <span class="input-group-text" (click)="openFileUpload('certificate_upload')">
                  <i class="fa fa-paperclip"></i>
                </span>
              </div>
            </div>
            <input type="file" id="certificate_upload" class="d-none" name="certificate_upload"
              (change)="uploadRegistrationCertificate($event)" accept=".pdf, .docx, .jpeg, .png" />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Address</label>
          <div class="col-lg-9 col-xl-6">
            <textarea class="form-control form-control-lg form-control-solid" type="text" value="Bold"
              formControlName="address" [class.is-invalid]="isControlInvalid('address')"
              [class.is-valid]="isControlValid('address')"></textarea>
          </div>
        </div>
        <div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">State</label>
            <div class="col-lg-9 col-xl-6" *ngIf="stateList">
              <select class="form-control form-control-lg form-control-solid" formControlName="stateId"
              [class.is-invalid]="isControlInvalid('stateId')" [class.is-valid]="isControlValid('stateId')"
              placeholder="Select State" (change)="getCityDDList($event.target.value)">
             
              <option *ngFor="let su of stateList" value="{{su.id}}">{{su.name}}</option>
            </select>
            <div class="form-text text-danger" *ngIf="controlHasError('required', 'stateId')">State is
              required
          </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">City</label>
            <div class="col-lg-9 col-xl-6" *ngIf="cityList">
              <select class="form-control form-control-lg form-control-solid"  formControlName="cityId"
              [class.is-invalid]="isControlInvalid('cityId')" [class.is-valid]="isControlValid('cityId')"  >
         
              <option  *ngFor="let su of cityList" value="{{su.id}}">{{su.name}}</option>
            </select>
            <div class="form-text text-danger" *ngIf="controlHasError('required', 'cityId')">City is
              required
          </div>
              <!-- <ng-select >

                <ng-option *ngFor="let su of cityList" [value]="su.id">{{su.Name}}</ng-option>

              </ng-select> -->
            </div>
          </div>

        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Location Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Search Location</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input type="text" class="form-control form-control-lg form-control-solid"
                (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off"
                autocapitalize="off" spellcheck="off" type="text" #mapSearch>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-3 offset-xs-3 col-lg-9 col-xl-6">
            <agm-map class="map-container" [latitude]="restaurant.latitude" [longitude]="restaurant.longitude">
              <agm-marker [latitude]="restaurant.latitude" [longitude]="restaurant.longitude" [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Form-->
  </div>
</ng-container>